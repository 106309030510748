<template>
  <div class="index">
    <div class="add">
      <h1>Add new phrase</h1>
      <fieldset>
        <label for="english">English</label>
        <input type="text" id="english" v-model="newPhrase.english.text" />
      </fieldset>
      <fieldset>
        <label for="thai">Thai</label>
        <input type="text" id="thai" v-model="newPhrase.thai.text" />
      </fieldset>
      <fieldset>
        <label for="script">Thai Transcript</label>
        <input type="text" id="script" v-model="newPhrase.thai.transcript" />
      </fieldset>
      <fieldset>
        <input
          id="genderneutral"
          type="checkbox"
          value="Yes"
          v-model="newPhrase.thai.isGenderNeutral"
        />
        <label for="genderneutral">Is Gender Neutral</label>
      </fieldset>
      <fieldset class="courses">
        <div
          class="course"
          v-for="categoryId in courseIds"
          v-bind:key="categoryId"
        >
          <input
            :id="categoryId"
            type="checkbox"
            :value="categoryId"
            v-model="newPhrase.categories"
          />
          <label :for="categoryId">{{ categoryId }}</label>
        </div>
      </fieldset>
      <button :disabled="disableAddButton" @click="add">Add</button>
    </div>
    <div class="edit">
      <h1>Edit phrases</h1>
      <div class="table">
        <div class="card" v-for="phrase in phrases" v-bind:key="phrase.id">
          <button :disabled="isLoading" class="save" @click="save(phrase)">Save</button>
          <input type="text" class="english" v-model="phrase.english.text" />
          <input type="text" class="thai" v-model="phrase.thai.text" />
          <input
            type="text"
            class="transcript"
            v-model="phrase.thai.transcript"
          />
          <div class="genderneutral">
            <input
              :id="phrase.id + 'genderneutral'"
              type="checkbox"
              value="Yes"
              v-model="phrase.thai.isGenderNeutral"
            />
            <label :for="phrase.id + 'genderneutral'">Is Gender Neutral</label>
          </div>
          <div class="courses">
            <div
              class="course"
              v-for="courseId in courseIds"
              v-bind:key="courseId"
            >
              <input
                :id="phrase.id + courseId"
                type="checkbox"
                :value="courseId"
                v-model="phrase.categories"
              />
              <label :for="phrase.id + courseId">{{ courseId }}</label>
            </div>
          </div>
        </div>
      </div>
      <button :disabled="disableLoadMoreButton" @click="fetch">
        {{ loadMoreText }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import lunr from 'lunr';
// import lunrdata from '@/assets/index.json'

export default {
  name: "index",
  data() {
    return {
      isLoading: false,
      newPhrase: {
        english: {
          text: "",
          tags: [],
        },
        thai: {
          text: "",
          transcript: "",
          isGenderNeutral: false,
        },
        categories: [],
      },
      disableLoadMoreButton: false,
      phrases: [],
    };
  },
  computed: {
    ...mapGetters(["courseIds"]),
    disableAddButton() {
      if (this.isLoading) return true;
      if (
        this.newPhrase.english.text === "" ||
        this.newPhrase.thai.text === "" ||
        this.newPhrase.thai.transcript === "" ||
        this.newPhrase.categories.length === 0
      ) {
        return true;
      }
      return false;
    },
    loadMoreText() {
      if (this.disableLoadMoreButton) {
        return "Loaded all phrases";
      }
      return "Load more phrases";
    },
  },
  methods: {
    ...mapActions(["addPhrase", "updatePhrase", "getPhrases"]),
    async add() {
      this.newPhrase.english.text = this.newPhrase.english.text.trim();
      this.newPhrase.thai.text = this.newPhrase.thai.text.trim();
      this.newPhrase.thai.transcript = this.newPhrase.thai.transcript.trim();
      if (
        this.newPhrase.english.text === "" ||
        this.newPhrase.thai.text === "" ||
        this.newPhrase.thai.transcript === ""
      ) {
        this.$notify.warning("Please fill out english, thai and thai script");
        return;
      }
      this.isLoading = true;
      const { data, hasError } = await this.addPhrase(this.newPhrase);
      console.log("has error:", hasError);
      console.log(data);
      if (hasError) {
        this.$notify.error(`Error: ${data}`);
        return;
      }
      this.$notify.success("Added new phrase");
      this.isLoading = false;
      this.newPhrase = {
        english: {
          text: "",
          tags: [],
        },
        thai: {
          text: "",
          transcript: "",
          isGenderNeutral: false,
        },
        categories: [],
      };
    },
    async save(phrase) {
      console.log("Save uid:", phrase.id);
      phrase.english.text = phrase.english.text.trim();
      phrase.thai.text = phrase.thai.text.trim();
      phrase.thai.transcript = phrase.thai.transcript.trim();
      if (
        phrase.english.text === "" ||
        phrase.thai.text === "" ||
        phrase.thai.transcript === ""
      ) {
        this.$notify.warning("Please fill out english, thai and thai script");
        return;
      }
      this.isLoading = true;
      const { data, hasError } = await this.updatePhrase({
        uid: phrase.id,
        phrase: phrase,
      });
      console.log("has error:", hasError);
      console.log(data);
      if (hasError) {
        this.$notify.error(`Error: ${data}`);
        return;
      }
      this.$notify.success("Updated phrase");
      this.isLoading = false;
    },
    async fetch() {
      let lastUid = null;
      if (this.phrases.length > 0) {
        lastUid = this.phrases[this.phrases.length - 1].id;
      }
      const { data, hasError, hasMore } = await this.getPhrases({
        lastUid: lastUid,
        count: 10,
      });
      this.disableLoadMoreButton = !hasMore;
      if (lastUid === null) {
        this.phrases = data;
      } else {
        this.phrases = [...this.phrases, ...data];
      }
      console.log(hasError);
      console.log(data);
      console.log("hasMore:", hasMore);
      // console.log("this.phrases,", this.phrases);
    },
  },
  mounted() {
    document.title = "Admin - PocketLang.com";
    this.fetch();
    // console.log(lunrdata)
    // var idx = lunr.Index.load(lunrdata)
    // console.log(idx)
    // console.log('drove', idx.search('drive'))
  },
};
</script>

<style scoped lang="scss">
select {
  height: 4em;
}
button {
  cursor: pointer;
  padding: 0.25em;
  font-size: 1.35em;
  border-radius: 0.25em;
  border: none;
  color: var(--button-text-color);
  background-color: var(--button-success-background-color);
}
button:disabled {
  cursor: initial;
  opacity: 0.8;
}
.index {
  // margin: 0 0.5rem;
  h1,
  h2 {
    color: white;
  }
  .add {
    padding: 1.5em 1em 2em 1em;
    background-color: #7c5cff;
    color: white;
    display: grid;
    grid-gap: 0.5em;
    input[type="text"] {
      box-sizing: border-box;
      font-size: 1.15em;
      display: block;
      width: 100%;
    }
    .courses {
      display: grid;
      grid-template-columns: repeat(auto-fill, 12.5em);
    }
  }
  .edit {
    padding: 1.5em 1em 2em 1em;
    color: white;
    display: grid;
    grid-gap: 0.5em;
    background-color: #001935;
    .table {
      display: grid;
      grid-gap: 0.75rem;
      .card {
        background-color: var(--phrase-background-color);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 0 2px rgba(0, 0, 0, 0.1);
        padding: 0.25rem;
        display: grid;
        grid-gap: 0.35rem;
        grid-template-areas:
          "english save"
          "thai save"
          "transcript save"
          "genderneutral genderneutral"
          "courses courses";
        grid-template-columns: 1fr auto;
        .english {
          grid-area: english;
        }
        .thai {
          grid-area: thai;
        }
        .transcript {
          grid-area: transcript;
        }
        .genderneutral {
          grid-area: genderneutral;
          color: initial;
        }
        .save {
          grid-area: save;
          width: 5em;
          height: 2em;
        }
        .english,
        .thai,
        .transcript {
          font-size: 1.15em;
          display: block;
          width: 100%;
          border: none;
          box-sizing: border-box;
        }
        .courses {
          display: grid;
          grid-area: courses;
          grid-template-columns: repeat(auto-fill, 12.5em);
          color: initial;
        }
      }
    }
  }
}
</style>